import request from '@/utils/request'

export function createGiftcode(giftCodeData) {
    return request({
        url: '/giftcodes',
        method: 'post',
        data: giftCodeData
    })
}

export function getAllCodes(start, limit) {
    return request({
        url: '/giftcodes/allcodes/' + start + '/' + limit,
        method: 'get'
    })
}

export function deleteCode(codeID) {
    return request({
        url: '/giftcodes/' + codeID,
        method: 'delete'
    })
}
import request from '@/utils/request'

export function createItem(itemData) {
    return request({
        url: '/items',
        method: 'post',
        data: itemData
    })
}

export function getAllItems() {
    return request({
        url: '/items/allitems',
        method: 'get'
    })
}

export function updateItemData(itemID, itemData) {
    return request({
        url: '/items/' + itemID,
        method: 'put',
        data: itemData
    })
}

export function deleteItem(itemID) {
    return request({
        url: '/items/' + itemID,
        method: 'delete'
    })
}
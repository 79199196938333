<template>
  <div class="app-container">
    <el-tabs v-model="activeNames" @tab-click="handleClick">
      <el-tab-pane label="Giftcodes" name="1">

        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              placeholder="Example: *, test, haoppd"
              v-model="exportString"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="onExport" >Export</el-button>
          </el-col>
        </el-row>

        <el-table :data="itemDataList
        " v-loading="loading" style="width: 100%" :default-sort = "{prop: 'id', order: 'descending'}">
          <el-table-column prop="id" label="ID" width="100px" sortable>
            <template slot-scope="{ row }">
              {{ row.id }}
            </template>
          </el-table-column>

          <el-table-column prop="name" label="Name" width="200px" sortable>
            <template slot-scope="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>

          <el-table-column label="Code">
            <template slot-scope="{ row }">
              <span>{{ row.code }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Creator" width="300px">
            <template slot-scope="{ row }">
              <span>{{ row.creator }}</span>
            </template>
          </el-table-column>
          <el-table-column label="User">
            <template slot-scope="{ row }">
              <span>{{ row.user }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Limited Usage">
            <template slot-scope="{ row }">
              <template v-if="row.limit_count <= 0">
                <span>(no limit)</span>
              </template>
              <template v-else>
                <span>{{ row.limit_count }}</span>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="Collected">
            <template slot-scope="{ row }">
              <span>{{ row.collected_count }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Rewards" type="expand" width="100px">
            <template slot-scope="{ row }">
              <span>{{ row.codeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Time Begin">
            <template slot-scope="{ row }">{{
              getDateString(row.time_begin)
            }}</template>
          </el-table-column>
          <el-table-column label="Time End">
            <template slot-scope="{ row }">{{
              getDateString(row.time_end)
            }}</template>
          </el-table-column>
          <el-table-column label="Status">
            <template slot-scope="{ row }">
              <el-tag v-if="row.status === 'Waiting'">{{ row.status }}</el-tag>
              <el-tag v-else-if="row.status === 'Activate'" type="success">{{
                row.status
              }}</el-tag>
              <el-tag v-else-if="row.status === 'collected'" type="success"
                >Collected</el-tag
              >
              <el-tag v-else type="info">{{ row.status }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template slot-scope="{ row }">
              <el-button
                v-if="row.deleteloading === true"
                disabled
                type="danger"
                size="small"
                icon="el-icon-delete"
                >Deleting...</el-button
              >
              <el-button
                v-else
                type="danger"
                size="small"
                icon="el-icon-delete"
                @click="onDelete(row)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="Add new giftcode" name="2">
        <el-form ref="form" :model="form" label-width="200px">
          <el-form-item label="Name">
            <el-input
              v-model="form.name"
              class="edit-input"
              size="small"
              style="width: 665px"
            />
          </el-form-item>

          <el-form-item label="Reward">{{ form.rewards }}</el-form-item>
          <div style="padding-left: 200px; padding-bottom: 20px">
            <add-reward v-model="form.rewards" />
          </div>
          <el-form-item label="Time Range">
            <el-date-picker
              v-model="form.date_range"
              type="datetimerange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="User (* for all)">
            <el-input
              v-model="form.user_id"
              class="edit-input"
              size="small"
              style="width: 665px"
            />
          </el-form-item>
          <el-form-item label="Limited Usage (0 : no limit)">
            <el-input
              v-model="form.limit_count"
              class="edit-input"
              size="small"
              style="width: 165px"
            />
          </el-form-item>

          <el-form-item label="Total Code (max: 100)">
            <el-input
              v-model="form.total"
              class="edit-input"
              size="small"
              style="width: 165px"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit"
              >Generate Giftcode</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AddReward from "@/components/AddReward";
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import convertDateString from "@/utils/get-date-string";
import { createGiftcode, getAllCodes, deleteCode } from "@/api/giftcodes";
import {  DownloadJS } from "@/api/gameservice";
import store from "@/store";

export default {
  name: "Giftcodes",
  components: { AddReward },
  data() {
    return {
      form: {
        name: "",
        rewards: [],
        date_range: [],
        user_id: "*",
        limit_count: 1,
        total: 1,
      },

      exportString: "",
      loading: true,
      itemDataList: [],
      activeNames: "1",
    };
  },
  created() {
    let now = new Date();

    // default begin is tomorrow
    this.form.date_range.push(now);

    // default end is next week
    this.form.date_range.push(
      new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)
    );

    // fetch list codes
    this.getListCodes();
  },
  methods: {
    onDelete(row) {
      MessageBox.confirm(
        "Do you confirm delete: " + row.code + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        row.deleteloading = true;
        deleteCode(row.default_id)
          .then((response) => {
            Message({
              message: response,
              type: "success",
              duration: 5 * 1000,
            });
            this.itemDataList
             = this.itemDataList
            .filter(function (ele) {
              return ele.default_id != row.default_id;
            });
          })
          .catch((error) => {
            row.deleteloading = false;
          });
      });
    },
    getListCodes() {
      this.loading = true;
      this.itemDataList
       = [];

      let now = new Date();

      getAllCodes(0, 100)
        .then((response) => {
          response.forEach((i) => {
            if (i.db_id === "giftcodes_data") {
              return;
            }

            let dateBegin = new Date(i.date_begin._seconds * 1000);
            let dateEnd = new Date(i.date_expired._seconds * 1000);
            let codeStatus = "Waiting";

            if (
              now.getTime() >= dateBegin.getTime() &&
              now.getTime() < dateEnd.getTime()
            ) {
              codeStatus = "Activate";
            } else if (now.getTime() > dateEnd.getTime()) {
              codeStatus = "Expired";
            }

            if (
              i.status_note !== undefined &&
              i.status_note !== null &&
              i.status_note !== ""
            ) {
              codeStatus = i.status_note;
            }

            let limit_count = 0;
            if (i.limit_count !== undefined) {
              limit_count = i.limit_count;
            }

            let id = 0;
            if (i.id !== undefined) {
              id = i.id;
            }

            let name = "old";
            if (i.name !== undefined) {
              name = i.name;
            }

            this.itemDataList
            .push({              
              default_id: i.db_id,
              id: id,
              name: name,
              code: i.code,
              user: i.user,
              limit_count: limit_count,
              collected_count: i.collected_count,
              creator: i.creator,
              codeName: JSON.stringify(i.rewards),
              time_begin: dateBegin,
              time_end: dateEnd,
              status: codeStatus,
              deleteloading: false,
            });
          });

          this.loading = false;
        })
        .catch((error) => {
          Message({
            message: "Can't fetch list giftcodes",
            type: "error",
            duration: 5 * 1000,
          });
          this.loading = false;
        });
    },

    //------------------------------------------------------------------------------------
    // export
    onExport() {
      // check name
      if (this.exportString.length == 0) {
        Message({
          message: "Filter string is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      // check name
      if (this.itemDataList.length == 0) {
        Message({
          message: "There is no item",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      let exportString ="";
      let firstValue = true;
      this.itemDataList.forEach(element => {
        let canExport = false;
        if (this.exportString === "*") {
          canExport = true;
        } else if (element.name.includes(this.exportString)) {
          canExport = true;
        } else if (element.creator.includes(this.exportString)) {
          canExport = true;
        } else if (element.status.includes(this.exportString)) {
          canExport = true;
        }

        if (canExport)
        {
          if (firstValue) {
            firstValue = false
            var titles = Object.keys(element);
            titles.forEach(title => {
              exportString += title + "\t";              
            });
            exportString += "\n";
          }

          var values = Object.values(element);
          values.forEach(value => {            
            exportString += value + "\t";            
          });
          exportString += "\n";
        }
      });

      exportString = exportString.trim();

      if (exportString.length > 0) {
        let export_filename = "export_giftcodes.mlb";
        DownloadJS(
          exportString,
          //exportList,
          //arrayToTable(exportList),
          //JSON.stringify(exportList),
          export_filename,
          "text/plain"
        );
      } else {
        Message({
          message: "No data to exported!",
          type: "error",
          duration: 5 * 1000,
        });
      }


    },

    //------------------------------------------------------------------------------------
    // add gift code
    onSubmit() {
      //------------------------------------------------------------------------------------
      // Send giftcode
      const sendGiftCodeData = async (data) => {
        let result = [];

        await createGiftcode(data)
          .then((response) => {
            result.push(response);
          })
          .catch((error) => {
            result.push(error);
          });

        return result;
      };
      //------------------------------------------------------------------------------------

      // check name
      if (this.form.name.length == 0) {
        Message({
          message: "Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      // check reward
      if (this.form.rewards.length == 0) {
        Message({
          message: "No rewards picked",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      // check time
      let now = new Date();
      if (this.form.date_range[0].getTime() < now.getTime() - 60 * 60 * 1000) {
        Message({
          message: "Date range must be in future",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      // check user
      if (isStringEmpty(this.form.user_id)) {
        Message({
          message: "User is empty",
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      MessageBox.confirm(
        "Do you confirm create new giftcode?",
        "Confirm create giftcode",
        {
          confirmButtonText: "Create",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let loadingInstance = Loading.service({
          lock: true,
          text: "Create giftcode...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        if (this.form.total > 100) {
          this.form.total = 100;
        }

        const promises = [];

        for (let i = 0; i < this.form.total; i++) {
          promises.push(
            sendGiftCodeData({
              creator: store.getters.email,
              user: this.form.user_id,
              rewards: this.form.rewards,
              date_begin: this.form.date_range[0].getTime(),
              date_end: this.form.date_range[1].getTime(),
              limit_count: this.form.limit_count,
              name: this.form.name,
              total: this.form.total,
            })
          );
        }

        Promise.all(promises).then((response) => {
          loadingInstance.close();
          Message({
            message: response,
            type: "success",
            duration: 5 * 1000,
          });

          // clear data
          this.form.user_id = "*";

          // refresh list code
          this.getListCodes();
        });
      });
    },

    //------------------------------------------------------------------------------------
    // confirmEdit
    confirmEdit(row) {
      row.editMode = false;
    },
    cancelEdit(row) {
      row.editMode = false;
    },
    handleClick() {},
    getDateString(d) {
      return convertDateString(d);
    },
  },
};
</script>
<template>
  <div>
    <el-transfer
      v-model="pickItem"
      filterable
      :data="data"
      :titles="['Items', 'Rewards']"
      :button-texts="['Remove', 'Add']"
      @change="handleChange"
      style="padding-bottom:30px"
      v-loading="loading"
    ></el-transfer>
    <el-card style="width:600px">
      <el-table :data="tableData">
        <el-table-column label="Name" width="400px">
          <template slot-scope="{row}">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Value">
          <template slot-scope="{row}">
            <el-input-number v-model="row.value" size="small" @change="handleChange" :min="0" />
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<style>
  .el-transfer-panel {
    width: 500px;
  }
</style>

<script>
import { Message } from "element-ui";
import { getAllItems } from "@/api/items";

export default {
  name: "AddReward",
  components: {},
  data() {
    return {
      data: [],
      pickItem: [],
      reward: [],
      dictionaryData: {},
      tableData: [],
      loading: true,

    };
  },
  created() {
    this.$emit("input", this.reward);
    this.getListItems();
  },
  methods: {
    getListItems() {
      getAllItems()
        .then(response => {
          response.forEach(i => {
            this.data.push({
              label: i.name,
              key: i.code
            });
          });
          this.loading = false;
        })
        .catch(error => {
          Message({
            message: "Can't fetch list items(1):" + error,
            type: "error",
            duration: 5 * 1000
          });
          this.loading = false;
        });
    },
    handleChange() {
      // clear current data
      this.tableData = [];

      // generate tab data
      this.pickItem.forEach(item => {
        if (this.dictionaryData[item] === undefined) {
          let itemName = this.data.find(function(element) {
            return element.key === item;
          });
          this.dictionaryData[item] = {
            name: itemName.label,
            code: item,
            value: 0
          };
        }

        // transfer to table
        this.tableData.push(this.dictionaryData[item]);
      });

      // generate reward string
      this.reward = [];
      this.tableData.forEach(item => {
        this.reward.push(item);
      });

      this.$emit("input", this.reward);

      this.$emit("CallbackChangeData", {
        rewards: this.reward
      });
    }
  }
};
</script>